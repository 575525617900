/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { leadershipBios, businessDevBios } from '../content/employees/leadershipBios';
import Image from '../components/ui/Image';
import ProfileCard from '../components/ui/ProfileCard';
import ProfileModal from '../content/employees/ProfileModal';
import TeamCard from '../content/employees/TeamCard';

import { header, textWrap, heroImage, outerWrap, lineStyles, leader, horizontalLine } from './leadershipStyles';
import GetInTouch from '../content/partners/GetInTouch';

export default class Team extends Component {
  state = {
    profileModal: false,
  };
  render() {
    const { profileModal } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>Team | DocProbe</title>
          <meta name='description' content='A leadership that inspires excellence. A team that serves with passion' />
        </Helmet>
        <div css={outerWrap}>
          <div className='relative'>
            <div css={lineStyles} />
            <div css={leader}>Leadership</div>
            <div css={textWrap}>
              <span>A leadership that inspires excellence.</span> A team that serves with passion.
            </div>
            <div>
              <Image image='team/avg-9866-edit_2x_tigpmt' styles={heroImage} />
            </div>
          </div>
        </div>
        <div css={horizontalLine} />
        <div className='container' css={{ maxWidth: 1230, marginBottom: 100, position: 'static' }}>
          <h2 css={header}>Leadership</h2>
          <div>
            {leadershipBios.map((i) => (
              <ProfileCard info={i} key={i.name} />
            ))}
          </div>
          <h2 css={header}>Business Development</h2>
          <div>
            {businessDevBios.map((i) => (
              <ProfileCard info={i} key={i.name} />
            ))}
          </div>
          <div>
            <h2 css={header}>Meet the Team</h2>
            <div css={{ maxWidth: 1113, display: 'flex', flexWrap: 'wrap' }}>
              <TeamCard img='c-jo_3x_ozjgm5' position='QC Auditor' name='Charlyn' />
              <TeamCard img='m-ru_3x_qyn1sw' position='Account Representative' name='Monica' />
              <TeamCard img='j-to_3x_r1wuhl' position='Senior Account Representative' name='Jaclyn' />
              <TeamCard img='m-jo_3x_y11unv' position='Account Representative' name='Megan' />
              <TeamCard img='j-kr_3x_wk4gps' position='Document Intake Team Lead' name='Jazmin' />
              <TeamCard img='s-ba_3x_q4xwxk' position='Business Analyst' name='Shmuel' />
              <TeamCard img='n-ca_3x_cusvyr' position='Senior Account Representative' name='Nicole' />
              <TeamCard
                img='j-ce_3x_cxit3y'
                position={
                  <span>
                    Account Representative
                    <br />
                    Team Lead
                  </span>
                }
                name='Jennifer'
              />
              <TeamCard img='l-ma_3x_ptl09o' position='Senior QC Auditor' name='Lisa' />
              <TeamCard img='p-sp_3x_eybwpo' position='Senior QC Auditor' name='Patricia' />
              <TeamCard
                img='faigy-nussbaum_3x_mt51vi'
                position={
                  <span>
                    Account Representative
                    <br />
                    Team Lead
                  </span>
                }
                name='Faigy'
              />
              <TeamCard img='k-no_3x_k68ibo' position='Senior QC Auditor' name='Kerri' />
              <TeamCard img='h-co_3x_wwwzhx' position='Digital QC Auditor' name='Heather' />
              <TeamCard img='d-br_3x_ov8sck' position='Senior Account Representative' name='Debra' />
              <TeamCard img='s-ch_3x_wvql82' position='Dev Ops' name='Shaya' />
              <TeamCard img='j-za_3x_qvlkui' position='QC Team Lead' name='Janel' />
              <TeamCard img='s-ma_3x_qfhldn' position='Shipping Specialist' name='Samantha' />
              <TeamCard img='c-li_3x_pq9vzy' position='Account Representative' name='Christine' />
              <TeamCard img='a-fr_3x_er879w' position='Senior Software Developer' name='Avrumi' />
              <TeamCard img='b-gr_3x_lqsulu' position='Senior Software Developer' name='Binyomin' />
              <TeamCard img='a-hu_3x_gtv2jp' position='Shipping Team Lead' name='Amy' />
              <TeamCard img='b-to_3x_uof3ir' position='Application Specialist' name='Ben' />
              <TeamCard img='d-le_3x_iwirfc' position='QC Verification Specialist' name='Danielle' />
              <TeamCard img='d-bu_3x_lotsw7' position='Account Representative' name='Diane' />
              <TeamCard img='k-va_3x_db1mps' position='Senior Account Representative' name='Katie' />
              <TeamCard img='d-we_3x_wvv8o9' position='IT Systems Support Specialist' name='Dov' />
              <TeamCard img='monica-ruiz_3x_clp82y' position='QC Auditor' name='Lytane' />
              <TeamCard img='allyson-simon_3x_m1r16f' position='Associate Account Representative' name='Allyson' />
              <TeamCard img='healy-april_3x_n638lm' position='Associate Digital QC Auditor ' name='April' />
              <TeamCard img='shifra-beigelman_3x_nonjb5' position='Document Intake Specialist' name='Shifra' />
              <TeamCard img='amanda-brunell_3x_chkaz7' position='Document Intake Specialist' name='Amanda' />
              <TeamCard img='christina-longo_3x_h8yekd' position='Document Intake Specialist' name='Christina' />
              <TeamCard img='soshi-kahana_3x_mjzyht' position='Mail Processor' name='Soshi' />
              <TeamCard img='kostova-ljupka_3x_xnepnh' position='QC Auditor' name='Ljupka' />
              <TeamCard img='corrine-hufnagle_3x_ezbboe' position='Associate Account Representative' name='Corrine' />
              <TeamCard img='jessica-mc-cann_3x_prx4fv' position='QC Verification Specialist' name='Jessica' />
              <TeamCard img='amanda-o-neil_3x_xnrnyy' position='Associate QC Auditor ' name='Amanda' />
              <TeamCard img='chaim-landau_3x_firayi' position='IT Systems Support Specialist' name='Chaim' />
              {/* <TeamCard img='jay-helmreich_gojapx.jpg' position='Business Operations Associate' name='Jay' /> */}
            </div>
          </div>
        </div>
        {profileModal && <ProfileModal closeModal={() => this.setState({ profileModal: !profileModal })} />}
        <GetInTouch />
      </Fragment>
    );
  }
}
