export const outerWrap = {
  paddingBottom: 58,
  position: 'relative',
  '@media(min-width: 1260px)': {
    margin: '0 auto',
    maxWidth: 1440,
    paddingBottom: 124,
  },
};
export const lineStyles = {
  '@media(max-width: 767px)': {
    display: 'none',
  },
  borderLeft: '1px solid #426d61',
  bottom: 0,
  content: '""',
  height: 'calc(100% + 58px)',
  left: 96,
  position: 'absolute',
  top: 0,
  '@media(min-width: 1260px)': {
    height: 'calc(100% + 124px)',
    left: 218,
  },
  '&:before': {
    borderTop: '1px solid #426d61',
    content: '""',
    left: -4,
    position: 'absolute',
    top: 0,
    width: 7,
  },
};
export const leader = {
  color: '#64ae3e',
  fontSize: 12,
  letterSpacing: 3,
  marginTop: 20,
  paddingLeft: 20,
  textTransform: 'uppercase',
  '@media(min-width: 768px)': {
    fontWeight: 500,
    marginLeft: 122,
    marginTop: 60,
  },
  '@media(min-width: 1260px)': { marginLeft: 260 },
};

export const textWrap = {
  fontSize: 37,
  lineHeight: 1.3,
  marginTop: '18px',
  paddingLeft: 20,
  span: {
    fontWeight: 500,
  },
  '@media(min-width: 768px)': {
    fontSize: 47,
    marginLeft: 122,
    marginTop: 32,
    maxWidth: 450,
  },
  '@media(min-width: 1260px)': {
    fontSize: 67,
    marginLeft: 260,
    maxWidth: 646,
  },
};
export const heroImage = {
  marginTop: 45,
  maxWidth: 1294,
  padding: '0 20px',
  position: 'relative',
  width: '100%',
  zIndex: '30',
  '@media(min-width: 1260px)': {
    margin: '50px auto 0',
  },
};
export const horizontalLine = {
  borderBottom: '1px solid #426d61',
  width: '100%',
};
export const header = {
  color: '#3b3f43',
  fontSize: 27,
  fontWeight: 500,
  margin: '55px 0 54px',
  textAlign: 'center',
  '@media(min-width: 768px)': {
    textAlign: 'left',
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    margin: '115px 0 54px',
  },
};