import React from 'react';

export const leadershipBios = [
  {
    bio: (
      <div>
        Nick Erlanger is Chief Executive Officer of DocProbe. In this position, Nick is responsible
        for all of the day-to-day operations of the company, from sales and marketing to software
        development. He ensures that all of the systems, resources, and people are in place to
        achieve continuous growth, and builds employee alignment with those goals in order to meet
        the key targets of the organization. Nick’s passionate leadership also drives the
        development and implementation of DocProbe’s corporate strategy. <br />
        <br />
        Prior to DocProbe, Nick was involved in a number of real estate and property management
        startups, getting critical hands-on knowledge of every aspect of the technology, marketing
        and sales components of these companies. Specifically, he was COO of BGS Control, a
        NYC-based company that designs and builds smart home products. Previously, Nick was Manager
        of Client Operations for SiteCompli, a technology company that enables clients to save time,
        money, and protect their brands from critical compliance risks using Compliance Automation
        Technology (CAT).
        <br />
        <br />
        When he puts his phone down, Nick enjoys spending time with his wife, playing flag football
        with his four boys, and riding a Harley on the back roads of Jackson, NJ, where he lives.
      </div>
    ),
    img: 'leadership/nick_3x_gnl0h4',
    name: 'Nick Erlanger',
    position: 'CEO',
    company: 'DocProbe',
    email: 'nerlanger@DocProbe.net',
    telNum: '7323332609',
  },
  {
    bio: (
      <div>
        Isabel Baumgarten, Esq. is Chief Operating Officer and General Counsel of Madison Commercial
        Real Estate Services (MCRES). With substantial experience in general corporate and business
        matters, Ms. Baumgarten complements the legal department in servicing the needs of the
        various companies under the MCRES umbrella, including Madison Title, Madison Exchange,
        Madison SPECs, LeaseProbe/Real Diligence and DocProbe.
        <br />
        <br />
        Prior to joining MCRES, Ms. Baumgarten served as Assistant Vice President of Operations at
        Garden City Group (GCG), a leading national claims administrator and a wholly owned
        subsidiary of Crawford & Company. While at GCG, Ms. Baumgarten directly oversaw dozens of
        legal case administrations and was ultimately promoted to lead the restructuring department.
        She was critical in developing standardized business processes and procedures and innovative
        client tools and initiatives.
        <br />
        <br />
        Before joining GCG, Ms. Baumgarten practiced as an attorney at Blank Rome, LLP and Healy and
        Baillie, LLP where she gained broad legal and business experience and expertise in a variety
        of matters including business restructuring, asset based financing, real estate
        condemnation, litigation, maritime and other general corporate matters.
      </div>
    ),
    img: 'leadership/baumgarten_3x_ondbue',
    name: 'Isabel Baumgarten',
    position: 'COO, General Counsel',
    company: 'DocProbe',
    email: 'IBaumgarten@docprobe.net',
  },
  {
    bio: false,
    img: 'leadership/siloutte-male_zkwemw.png',
    name: 'Menachem Locker',
    position: 'Senior VP, Software Engineering',
    company: 'DocProbe',
  },
  {
    bio: (
      <div>
        Charles Celnik is Controller for Madison Commercial Real Estate Services and all its
        affiliated companies including Madison Title. Mr. Celnik oversees all accounting functions
        and prepares Financial Statements for Madison’s joint ventures and related parties. Mr.
        Celnik began his accounting career at Ernst & Young, LLP, where he was primarily responsible
        for tax structures for multi-state companies dealing with state and local taxes and audits
        for Sarbanes-Oxley compliance. From there, Mr. Celnik went to Home Décor Products, where he
        acted as the Internal Auditor in preparation for a possible IPO. <br />
        <br /> Mr. Celnik received a Bachelor’s degree from the Rabbinical College of Long Island.
        He also holds an MBA in Finance and Accounting from Rutgers University. Mr. Celnik is
        actively involved in the community with a number of organizations.
      </div>
    ),
    img: 'leadership/celnik_3x_f5fgfl',
    name: 'Chaim Celnik',
    position: 'Controller',
    company: 'Madison Commercial Real Estate Services',
  },
  {
    bio: (
      <div>
        Gitty Bergstein is the Operations Manager for DocProbe. In this capacity, Mrs. Bergstein is
        responsible for overseeing complete operational workflow including setting up and handling
        client projects, implementing and enforcing new procedures, hiring and managing staff,
        onboarding new client work processes based on their specific needs and managing ongoing
        client relationships. Mrs. Bergstein also monitors team output on a daily basis.
        <br />
        <br />
        Mrs. Bergstein earned her Accounting degree from Maalot College in Israel in 2013. She
        joined the DocProbe team shortly thereafter and has grown with the company in responsibility
        since then. When she is not working, Mrs. Bergstein is actively involved with her family and
        community.
      </div>
    ),
    img: 'leadership/bergstein_3x_ish3rp',
    name: 'Gitty Bergstein',
    position: 'AVP, Operations',
    company: 'DocProbe',
    email: 'gbergstein@DocProbe.net',
    telNum: '7328064681',
  },
  {
    bio: (
      <div>
        Tzivia Waidenbaum is the Director of Human Resources for Madison Commercial Real Estate
        Services and its affiliate companies. In this role, she works with the Executive Management
        to create and implement the employment and compliance policies and programs for the entire
        organization.
        <br />
        <br />
        In sync with Madison’s continued growth over the last few years, Mrs. Waidenbaum has
        directly overseen and managed the numerous and ongoing challenges that accompany such
        expansion. These include recruiting and staffing, employee training and their development to
        attain the quality standards needed in the fast paced and demanding Real Estate field. She
        has been able to accomplish all this while putting a specific emphasis on safety and
        compensation initiatives, ensuring the welfare and equal opportunity for all employees. With
        over 13 years heading the department, Mrs. Waidenbaum has the ability to connect with each
        employee and understand their individual needs, and steering each one’s unique strengths to
        help the company keep growing in the proper direction.
        <br />
        <br />
        Before her position as head of HR, Mrs. Waidenbaum spent five years as a title producer for
        Madison Title. Prior to joining Madison, Mrs. Waidenbaum was an administrator for a private
        school. When she is not working, Mrs. Waidenbaum is active with family life and charitable
        activities in the community.
      </div>
    ),
    img: 'leadership/weidenbaum_3x_otk5jm',
    name: 'Tziviah Waidenbaum',
    position: 'Director of HR',
    company: 'Madison Commercial Real Estate Services',
  },
  {
    bio: (
      <div>
        Meir Liebster is the Chief Technology Officer for Madison Commercial Real Estate Services
        and all its affiliated companies. In this role he is responsible to oversee the building and
        maintaining of the IT infrastructure, IT related projects and Help Desk functions. Mr.
        Liebster combines the unique character mix of being process and efficiency driven, and at
        the same time being able to build effective teams using proven motivational skills. His deep
        knowledge of systems and technologies allows him to see the bigger picture, while remaining
        focused on the user experience.
        <br />
        <br />
        Previously, Mr. Liebster managed a full service independent IT service provider, and built
        and managed his own successful MSP. He has an MBA with a focus on marketing, giving him the
        business perspective needed to find the proper path and solutions when dealing with internal
        and client issues.
      </div>
    ),
    img: 'leadership/liebster_3x_feapnh',
    name: 'Meir Liebster',
    position: 'CTO',
    company: 'Madison Commercial Real Estate Services',
  },
];

export const businessDevBios = [
  {
    bio: (
      <div>
        Richard joined DocProbe in May of 2017 coming from a non-competing vendor and has been
        serving Lenders from the vendor space since 2013.
        <br />
        Richard is a graduate of Union University (Jackson, TN) and is experienced in mortgage sales
        and operations both residential and commercial.
        <br />
        Richard has successfully developed new territories and introduced mortgage solutions to the
        market dating back to 1995.
        <br />
        Favorite part of working at DocProbe is helping Lenders streamline operations and cut cost
        by making trailing documents easy just as our tag line promises.
        <br />
        Richard is married with three children and enjoys family, traveling and outdoor recreation
        as a great work / life balance.
      </div>
    ),
    img: 'leadership/scudder_3x_g13qzh',
    name: 'Richard Scudder',
    position: 'Senior BD Executive',
    company: 'DocProbe',
  },
];
